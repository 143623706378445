import { VideoPlayer } from "@zoom/videosdk"
import React from "react"

import { makeStyles } from "@material-ui/core"
import useComponentSize from "@rehooks/component-size"
import clsx from "clsx"
import { useSubscription } from "observable-hooks"
import { ConferenceContext } from "../../ConferenceContext"
import { UserIdentification } from "../UserIdentification/UserIdentification"

export function ConferenceParticipants() {
  const api = React.useContext(ConferenceContext)
  const mediaStream = api.mediaStream
  const videoPlayerListRef = React.useRef<Record<string, VideoPlayer>>({})

  const setVideoPlayerRef = (userId: number, element: VideoPlayer | null) => {
    if (element) {
      videoPlayerListRef.current[`${userId}`] = element
    }
  }

  const participantsApi = api.participants

  const { list: participantsList } = participantsApi

  const ref = React.useRef<HTMLDivElement>(null)
  const size = useComponentSize(ref)

  const rootWidth = size.width || 0

  const isSingleParticipant = participantsList?.length === 1
  const isTwoParticipants = participantsList?.length === 2

  const numberOfColumns =
    rootWidth < 600 || isSingleParticipant
      ? 1
      : rootWidth < 1000 || isTwoParticipants
      ? 2
      : 3

  const c = useStyles({ numberOfColumns })

  const activeSpeakerId = React.useContext(ConferenceContext).audio.activeUserId

  const startVideo = React.useCallback(
    (userId: number) => {
      const attachment = videoPlayerListRef.current[`${userId}`]
      console.log(attachment, videoPlayerListRef)
      if (attachment) {
        const v = mediaStream?.attachVideo(userId, 1, attachment)

        console.log(v)
      }
    },
    [mediaStream]
  )

  window.startVideo = startVideo

  useSubscription(
    participantsApi.addedParticipantsIds$,
    React.useCallback(
      (addedUsers: number[]) => {
        addedUsers.forEach((userId) => {
          startVideo(userId)
          // const attachment = videoPlayerListRef.current[`${userId}`]
          // if (attachment) {
          //   mediaStream?.attachVideo(userId, 1, attachment)
          // }
        })
      },
      [mediaStream, startVideo]
    )
  )

  useSubscription(
    participantsApi.removedParticipantsIds$,
    React.useCallback(
      (removedUsers: number[]) => {
        removedUsers.forEach((userId) => {
          mediaStream?.detachVideo(userId)
        })
      },
      [mediaStream]
    )
  )

  // const start = () => ()

  return (
    <div className={clsx(c.root, "conference-participants")} ref={ref}>
      <video-player-container class={c.videoPlayerContainer}>
        {participantsList.map((user) => {
          return (
            <div
              key={user.userId}
              className={c.participant}
              style={{
                padding: 8,
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  background: user.bVideoOn
                    ? "rgba(0,0,0,0.05)"
                    : "rgba(0,0,0,0.1)",
                }}
              >
                {(() => {
                  const isActiveSpeaker = user.userId === activeSpeakerId
                  const displayName = participantsApi.getAppUser({
                    displayName: user.displayName,
                  })?.name

                  if (!user.bVideoOn)
                    return (
                      <UserIdentification
                        className={c.userWithoutVideo}
                        displayName={displayName || ""}
                        key={user.userId}
                        isActive={isActiveSpeaker}
                        variant="centered-display-name"
                      />
                    )

                  return (
                    <UserIdentification
                      key={user.userId}
                      variant="left-display-name"
                      isActive={isActiveSpeaker}
                      displayName={displayName || ""}
                    >
                      <video-player
                        node-id={user.userId}
                        ref={(element) => {
                          setVideoPlayerRef(user.userId, element)
                        }}
                      />
                    </UserIdentification>
                  )
                })()}
              </div>
            </div>
          )
        })}
      </video-player-container>
    </div>
  )
}

type Styles = {
  numberOfColumns: number
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    display: "flex",

    justifyContent: "center",
    alignItems: "center",
  },
  videoPlayerContainer: {
    width: "100%",
    margin: theme.spacing(-2),
    display: "flex !important",
    flexWrap: "wrap",

    "&>*": {
      padding: theme.spacing(1),
      width: (p: Styles) => {
        return 100 / p.numberOfColumns + "%"
      },
      aspectRatio: "16/9",
      height: "auto",
    },

    "& video-player": {
      width: "100%",
      height: "100%",
    },
  },
  participant: {
    "&>*": {
      "&, &>*": {
        height: "100%",
        // background: "rgba(0, 0, 0, 0.05)",
        width: "100%",
      },
    },
  },

  userWithoutVideo: {
    // background: "rgba(0, 0, 0, 0.5)",
  },
}))
